import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useRedirect } from '../../hooks/useRedirect'
import { borders, colors } from '../../theme'
import CheckboxList, { StyledCheckboxList } from '../../components/CheckboxList'
import CheckboxTile from '../../components/CheckboxTile'
import { Button, Pulse } from '@monbanquet/crumble'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { EventContext } from '../../components/context/EventContext'
import {
  CheckoutContext,
  sendDemand,
  DEMAND_STATUS,
} from '../../components/context/CheckoutContext'
import { PhoneContext } from '../../components/context/PhoneContext'
import { CustomerContext } from '../../components/context/CustomerContext'
import { navigate } from '../../components/Link'
import { useWithDelay } from '../../hooks/useWithDelay'
import DemandeLayout from '../../components/DemandeLayout'
import { buildAskForQuoteBody, computeNextPageWithBaseUri } from '../../util'

const DrinksPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const [error, setError] = useState()

  const { state: checkoutState, dispatch: dispatchCheckoutCtx } = useContext(
    CheckoutContext,
  )

  const { customer } = useContext(CustomerContext)

  const {
    state: eventState,
    dispatch: dispatchEventCtx,
    categories,
  } = useContext(EventContext)

  const {
    state: { e164 },
  } = useContext(PhoneContext)

  const submit = async () => {
    const nextUrl = computeNextPageWithBaseUri('demande', 'boissons', {
      isUserLoggedIn: !!customer,
      customer,
    })

    if (nextUrl) {
      navigate(nextUrl, {
        state: { next: true },
      })
    } else {
      const body = buildAskForQuoteBody({
        ...checkoutState,
        ...eventState,
        categories,
      })

      const { status, response } = await sendDemand(dispatchCheckoutCtx, body)

      if (status === DEMAND_STATUS.OK) {
        navigate(
          `${process.env.GATSBY_VENUS_FRONT_URL}/evenements/${response.demandId}`,
          { newTab: false },
        )
      } else if (status === DEMAND_STATUS.NEED_MANUAL_OPERATION) {
        navigate('/demande/merci', { state: { quote: true } })
      } else if (status === DEMAND_STATUS.KO) {
        const errMsg = `Nous n'avons pas réussi à enregistrer votre demande. Veuillez nous contacter sur le chat ou par téléphone au ${e164}.`
        setError(errMsg)
        dispatchEventCtx({
          type: 'UPDATE',
          payload: { demandSource: null },
        })
      }
    }
  }

  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  return (
    <StyledDrinksPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`Quelles boissons vous intéressent\u00A0?`}
            />
            <CheckboxList
              tiles={eventState.drinks}
              onTileSelected={(tile, tiles) => {
                dispatchEventCtx({
                  type: 'UPDATE_DRINKS',
                  payload: { drinks: tiles },
                  analytics: { label: 'From_DrinksPage' },
                })
              }}
              renderTile={<CheckboxTile />}
            />

            <p className="error">{error}</p>

            <div className="btn-bar">
              <Button id="previous" onClick={() => navigate(-1)}>
                Précédent
              </Button>
              <Button id="next" onClick={submitWithDelay}>
                {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
              </Button>
            </div>
          </div>
        )}
      </DemandeLayout>
    </StyledDrinksPage>
  )
}

const StyledDrinksPage = styled.div`
  .page-content {
    max-width: 750px;
  }

  ${StyledCheckboxList} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-left: -7px;
    margin-right: -7px;

    & label {
      font-size: 1.1rem;
    }

    & > * {
      margin: 7px;
      max-width: 120px;
      flex: 1 1 110px;

      &:hover {
        border-color: ${borders.color.pg};
        background-color: ${colors.background.semiLight};
      }
    }
  }

  .error {
    margin-bottom: 10px;
    color: ${colors.brand.dark2};
  }
`

export default DrinksPage
export { StyledDrinksPage }
